









































  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { namespace } from 'vuex-class'
  import { Stocks } from '@one/types'
  import { BindingHelpers } from 'vuex-class/lib/bindings'

  const account: BindingHelpers = namespace('account')

  @Component({
    components: {
      OneDropdown: () => import('~/components/molecules/dropdowns/OneDropdown.vue'),
      OneTooltipComponent: () => import('~/components/molecules/tooltips/OneTooltip.vue'),
    },
  })
  export default class OneTopWarehouses extends Vue {
    @account.Getter warehouseSelectionBlocked: any;
    @account.Getter getAdditionalWarehousesIds: any;
    @account.Getter getAllWarehouses: any;

    @Prop({
      type: Array,
      required: true,
    })
    warehouses!: Array<Stocks.Warehouse>;

    @Prop({
      type: Object,
      required: true,
    })
    currentWarehouse!: Stocks.Warehouse;

    selectedWarehouse: Stocks.Warehouse | null = null;

    get disableWarehouseChange() {
      return this.warehouseSelectionBlocked && this.getAdditionalWarehousesIds.length === 0
    }

    get selectedWarehouseOption() {
      if (!this.currentWarehouse) { return null }
      return {
        id: this.currentWarehouse.id,
        text: this.currentWarehouse.label,
      }
    }

    get selectableWarehouses() {
      if (!this.warehouseSelectionBlocked) {
        return this.warehouses.map((x: Stocks.Warehouse) => ({
          id: x.id,
          text: x.label,
        }))
      } else {
        const result = this.warehouses.filter((warehouse: Stocks.Warehouse) => this.getAllWarehouses.includes(warehouse.id))
        return result.map((warehouse: Stocks.Warehouse) => ({
          id: warehouse.id,
          text: warehouse.label,
        }))
      }
    }

    get officeText() {
      if (this.currentWarehouse) {
        return `${this.$t('header.selected_office')}: ${this.currentWarehouse.label || '-'}`
      }
      return this.$t('header.select_office') as string
    }

    get selectedWarehouseStreet() {
      const { street, number } = this.selectedWarehouse!.address!
      return [street, number].filter(a => a).join(' ')
    }

    get selectedWarehouseCountry() {
      const { zip, city, country } = this.selectedWarehouse!.address!
      const address = [city, country].filter(a => a).join(', ')
      return [zip, address].filter(a => a).join(' ')
    }

    onWarehouseSelection(id: string) {
      // @ts-ignore
      this.selectedWarehouse = this.warehouses.find((x: Stocks.Warehouse) => x.id === id)
    }

    setWarehouse() {
      if (this.selectedWarehouse) {
        this.$emit('warehouse-selected', this.selectedWarehouse.id)
        // @ts-ignore
        this.$refs.dropdownItem.hide(true)
      }
    }
  }
